import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import vidWorkCover from "../assets/image/Shluchim App/Features.mp4"
import vidPoster from "../assets/image/Shluchim App/poster.jpg"
import imgS1 from "../assets/image/Shluchim App/1.png";
import imgS2 from "../assets/image/Shluchim App/2.png";
import imgS3 from "../assets/image/Shluchim App/3.png";
import imgS4 from "../assets/image/Shluchim App/4.png";
import imgS5 from "../assets/image/Shluchim App/5.png";
import imgS6 from "../assets/image/Shluchim App/6.png";
import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Education</Text>
                  <Title className="my-4">
                    Pause 4 Purpose
                  </Title>
                  <Text
                    variant="p"
                    css={`max-width: 750px;`}
                  >
                    Pause 4 Purpose is a platform to schedule and manage your private and group virtual classes and to expand 
                    one's range of influence beyond the confines of geography. The platform includes support for both one-on-one 
                    or group classes and includes a powerful search engine, an elaborate availability schedule, booking lessons 
                    and extensive live session features.
                  </Text>
                </Box>
                <Box css={`margin-top: 25px;`}>
                  <Text>
                    Visit at: <a href="https://pause4purpose.com">&nbsp;pause4purpose.com</a>
                  </Text>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <video poster={vidPoster} alt="" className="img-fluid w-100" controls autoplay>
                <source src={vidWorkCover} type="video/mp4" />
              </video>
            </Box>
          </Container>
        </Section>
        <Section className="mt-lg-5 pb-0">
          <Container>
            <Row>
              <Col lg="6" className="mb-5">
                <img src={imgS1} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={imgS6} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section bg="dark" className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="../portfolio-teachers">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Teacher Platform
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
